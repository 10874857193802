import { Action } from '@reduxjs/toolkit';
import {
  ActionPattern,
  ForkEffect,
  call,
  fork,
  put,
  race,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

export function withCatch<Fn extends (...args: any[]) => any>(saga: Fn) {
  return function* withCatchSaga(...args: Parameters<Fn>) {
    try {
      yield call<Fn>(saga, ...args);
    } catch (error: any) {
      console.error(error);
    }
  };
}

export function takeFirst<A extends Action>(
  pattern: ActionPattern<A>,
  worker: (action: A) => any,
): ForkEffect<never> {
  return fork(function* () {
    while (true) {
      const action = yield take(pattern);
      yield call(worker, action);
    }
  });
}

export function takeFirstSafe<A extends Action>(
  pattern: ActionPattern<A>,
  worker: (action: A) => any,
): ForkEffect<never> {
  return takeFirst(pattern, withCatch(worker));
}

export function withCancel<
  Fn extends (...args: any[]) => any,
  P extends ActionPattern,
>(pattern: P, fn: Fn) {
  return function* _cancel(...args: Parameters<Fn>) {
    // TODO: remove cast after update to redux to v5
    // incompatibility with Action type from redux
    yield put({ type: pattern as any });
    yield race({
      task: call<Fn>(fn, ...args),
      cancel: take(pattern),
    });
  };
}

export function takeEverySafe<A extends Action>(
  pattern: ActionPattern<A>,
  worker: (action: A) => any,
): ForkEffect<never> {
  return takeEvery(pattern, withCatch(worker));
}

export function takeLatestSafe<A extends Action>(
  pattern: ActionPattern<A>,
  worker: (action: A) => any,
): ForkEffect<never> {
  return takeLatest(pattern, withCatch(worker));
}
